.content-title-product-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.content-title-product-text {
  color: rgb(0, 0, 0);
  font-size: 36px;
  font-style: normal;
  font-family: "Gotham-Bold";
  line-height: 40px;
  letter-spacing: 0.25px;
}
 
.content-title-productroot-class-name {
  max-width: 800px;
}
 
.content-title-productroot-class-name1 {
  max-width: 800px;
}
 
.content-title-productroot-class-name2 {
  max-width: 800px;
}
 
.content-title-productroot-class-name3 {
  max-width: 800px;
}
