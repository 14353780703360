.web3-infrastructure-blueprint-viral-digital-asset-container1 {
  gap: var(--dl-layout-space-unit);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding-top: var(--dl-layout-space-oneandhalfunits);
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-layout-space-oneandhalfunits);
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.web3-infrastructure-blueprint-viral-digital-asset-content {
  gap: var(--dl-layout-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: center;
  flex-direction: column;
}
 
.web3-infrastructure-blueprint-viral-digital-asset-hero {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.web3-infrastructure-blueprint-viral-digital-asset-description {
  gap: var(--dl-layout-space-halfunit);
  width: 421px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.web3-infrastructure-blueprint-viral-digital-asset-navlink1 {
  display: contents;
}
 
.web3-infrastructure-blueprint-viral-digital-asset-component17 {
  text-decoration: none;
}
 
.web3-infrastructure-blueprint-viral-digital-asset-container2 {
  gap: var(--dl-layout-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-layout-space-sixunits);
  padding-right: var(--dl-layout-space-sixunits);
  flex-direction: column;
}
 
.web3-infrastructure-blueprint-viral-digital-asset-cards {
  gap: var(--dl-layout-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
.web3-infrastructure-blueprint-viral-digital-asset-container3 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.web3-infrastructure-blueprint-viral-digital-asset-text {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300px;
  line-height: 32px;
  font-stretch: normal;
  letter-spacing: 0.5px;
}
 
.web3-infrastructure-blueprint-viral-digital-asset-container4 {
  gap: var(--dl-layout-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.web3-infrastructure-blueprint-viral-digital-asset-component51 {
  text-decoration: none;
}
 
.web3-infrastructure-blueprint-viral-digital-asset-testimonials {
  gap: var(--dl-layout-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
.web3-infrastructure-blueprint-viral-digital-asset-navlink2 {
  display: contents;
}
 
.web3-infrastructure-blueprint-viral-digital-asset-component55 {
  text-decoration: none;
}
 
@media(max-width: 1200px) {
  .web3-infrastructure-blueprint-viral-digital-asset-container1 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .web3-infrastructure-blueprint-viral-digital-asset-testimonials {
    gap: var(--dl-layout-space-unit);
    width: 100%;
    flex-direction: row;
  }
}
 
@media(max-width: 767px) {
  .web3-infrastructure-blueprint-viral-digital-asset-testimonials {
    flex-direction: column;
  }
}
 
@media(max-width: 550px) {
  .web3-infrastructure-blueprint-viral-digital-asset-container1 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .web3-infrastructure-blueprint-viral-digital-asset-content {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
  }
  .web3-infrastructure-blueprint-viral-digital-asset-hero {
    flex-direction: column-reverse;
  }
  .web3-infrastructure-blueprint-viral-digital-asset-description {
    width: 100%;
  }
  .web3-infrastructure-blueprint-viral-digital-asset-container2 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .web3-infrastructure-blueprint-viral-digital-asset-cards {
    flex-direction: column;
  }
  .web3-infrastructure-blueprint-viral-digital-asset-testimonials {
    flex-direction: column;
  }
}
