.research-container1 {
  gap: var(--dl-layout-space-threeunits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.research-hero {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-layout-space-sixunits);
  flex-direction: column;
}
 
.research-frame1171275211 {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.research-text10 {
  color: rgb(0, 0, 0);
  width: 1126px;
  height: auto;
  font-size: 72px;
  font-style: Black;
  text-align: center;
  font-family: "Gotham-Ultra";
  font-weight: 900;
  line-height: 72px;
  font-stretch: normal;
  text-decoration: none;
}
 
.research-cubebitmin1 {
  width: 862px;
  height: 249px;
  z-index: 1;
}
 
.research-frame482057 {
  gap: 120px;
  width: 1126px;
  height: 166px;
  display: flex;
  padding: 48px;
  z-index: 2;
  overflow: hidden;
  box-shadow: 0px 4px 32px -12px rgba(0, 0, 0, 0.25) ;
  margin-top: -96px;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 2px 0 0 2px;
  border-radius: 29px;
  flex-direction: column;
  backdrop-filter: blur(64px);
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}
 
.research-text13 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.research-container2 {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 800px;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
 
.research-text17 {
  color: var(--dl-color-default-maintext);
  font-size: 18px;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  text-decoration: none;
}
 
.research-text18 {
  color: var(--dl-color-default-maintext);
  font-size: 18px;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  text-decoration: none;
}
 
.research-text19 {
  color: var(--dl-color-default-maintext);
  font-size: 18px;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  text-decoration: none;
}
 
.research-text20 {
  color: var(--dl-color-default-maintext);
  font-size: 18px;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  text-decoration: none;
}
 
.research-text21 {
  color: var(--dl-color-default-maintext);
  font-size: 18px;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  text-decoration: none;
}
 
.research-scheme {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.research-component6 {
  text-decoration: none;
}
 
@media(max-width: 1200px) {
  .research-container1 {
    padding-bottom: 0px;
  }
  .research-hero {
    height: auto;
    position: relative;
    padding-top: var(--dl-layout-space-fiveunits);
  }
  .research-text17 {
    color: var(--dl-color-default-maintext);
  }
  .research-text18 {
    color: var(--dl-color-default-maintext);
  }
  .research-text19 {
    color: var(--dl-color-default-maintext);
  }
  .research-text20 {
    color: var(--dl-color-default-maintext);
  }
  .research-text21 {
    color: var(--dl-color-default-maintext);
  }
}
 
@media(max-width: 550px) {
  .research-container1 {
    gap: var(--dl-layout-space-unit);
    padding-bottom: 0px;
  }
  .research-hero {
    height: auto;
    padding-top: var(--dl-layout-space-oneandhalfunits);
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
  }
  .research-text10 {
    color: rgb(0, 0, 0);
    width: 100%;
    font-size: 48px;
    font-family: Gotham-Ultra;
    font-weight: 900;
    line-height: 48px;
    text-decoration: none;
  }
  .research-cubebitmin1 {
    width: 100%;
    height: 135px;
  }
  .research-frame482057 {
    width: 100%;
    height: auto;
    padding: var(--dl-layout-space-oneandhalfunits);
  }
  .research-text13 {
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: none;
  }
  .research-container2 {
    width: 100%;
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
  }
}
