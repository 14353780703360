.stakeway-product-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.stakeway-product-frame1171275250 {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 56px 48px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: 0px 32px 20px -24px rgba(137, 80, 250, 0.25) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.stakeway-product-text1 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: Black;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 900;
  line-height: 48px;
  font-stretch: normal;
  text-decoration: none;
}
 
.stakeway-product-text2 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.stakeway-product-link1 {
  display: contents;
}
 
.stakeway-product-frame1171275247 {
  gap: 4px;
  display: flex;
  align-items: center;
  text-decoration: none;
}
 
.stakeway-product-text3 {
  color: var(--dl-color-default-maininteractive);
  height: auto;
  font-size: 16px;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.stakeway-product-chevronright {
  width: 24px;
  height: 24px;
}
 
.stakeway-product-link2 {
  display: contents;
}
 
.stakeway-product-frame1171275239 {
  gap: 24px;
  width: auto;
  display: flex;
  padding: 13px 23px;
  align-self: stretch;
  box-shadow: 0px 4px 4px 0px rgba(137, 80, 250, 0.25) ;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(137, 80, 250, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 32px;
  justify-content: space-between;
  text-decoration: none;
  background-color: rgba(39, 48, 60, 1);
}
 
.stakeway-product-text4 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  font-style: Black;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.stakeway-product-roomserviceoutline {
  width: 24px;
  height: 24px;
}
 
@media(max-width: 550px) {
  .stakeway-product-container {
    height: auto;
  }
  .stakeway-product-frame1171275250 {
    padding-top: var(--dl-layout-space-twounits);
    padding-left: var(--dl-layout-space-oneandhalfunits);
    padding-right: var(--dl-layout-space-oneandhalfunits);
    padding-bottom: var(--dl-layout-space-twounits);
  }
}
