.blog-staking-market-container1 {
  gap: var(--dl-layout-space-twounits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.blog-staking-market-container2 {
  gap: var(--dl-layout-space-twounits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: flex-start;
  padding-top: var(--dl-layout-space-threeunits);
  flex-direction: column;
}
 
.blog-staking-market-post {
  gap: 32px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.blog-staking-market-gatewaywirexcasestudy1 {
  width: 100%;
  height: auto;
  align-self: stretch;
  border-radius: 30px;
}
 
.blog-staking-market-text100 {
  gap: 32px;
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.blog-staking-market-container3 {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.blog-staking-market-tags {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
 
.blog-staking-market-text101 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-market-text102 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-market-text103 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-market-text104 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  line-height: 28px;
  letter-spacing: 0em;
  text-decoration: none;
}
 
.blog-staking-market-text105 {
  font-family: "Gotham-Bold";
  font-weight: 700;
}
 
.blog-staking-market-text107 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  line-height: 28px;
  letter-spacing: 0em;
  text-decoration: none;
}
 
.blog-staking-market-text108 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-market-text111 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  line-height: 28px;
  letter-spacing: 0em;
  text-decoration: none;
}
 
.blog-staking-market-text114 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-market-text127 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  line-height: 28px;
  letter-spacing: 0em;
  text-decoration: none;
}
 
.blog-staking-market-text128 {
  font-family: "Gotham-Bold";
}
 
.blog-staking-market-text130 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  line-height: 28px;
  letter-spacing: 0em;
  text-decoration: none;
}
 
.blog-staking-market-text133 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-market-text136 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  line-height: 28px;
  letter-spacing: 0em;
  text-decoration: none;
}
 
.blog-staking-market-text139 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-market-text142 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Bold";
  line-height: 28px;
  letter-spacing: 0em;
  text-decoration: none;
}
 
.blog-staking-market-text143 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  line-height: 28px;
  letter-spacing: 0em;
  text-decoration: none;
}
 
.blog-staking-market-text146 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-market-text153 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  line-height: 28px;
  letter-spacing: 0em;
  text-decoration: none;
}
 
.blog-staking-market-text154 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-market-text157 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Bold";
  line-height: 28px;
  letter-spacing: 0em;
  text-decoration: none;
}
 
.blog-staking-market-text158 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  line-height: 28px;
  letter-spacing: 0em;
  text-decoration: none;
}
 
.blog-staking-market-text161 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-market-text170 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  line-height: 28px;
  letter-spacing: 0em;
  text-decoration: none;
}
 
.blog-staking-market-text173 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-market-text182 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Bold";
  line-height: 28px;
  letter-spacing: 0em;
  text-decoration: none;
}
 
.blog-staking-market-text183 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  line-height: 28px;
  letter-spacing: 0em;
  text-decoration: none;
}
 
.blog-staking-market-text186 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-market-text189 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  line-height: 28px;
  letter-spacing: 0em;
  text-decoration: none;
}
 
.blog-staking-market-text190 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-market-text199 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Bold";
  line-height: 28px;
  letter-spacing: 0em;
  text-decoration: none;
}
 
.blog-staking-market-text202 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-market-text216 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Bold";
  line-height: 28px;
  letter-spacing: 0em;
  text-decoration: none;
}
 
.blog-staking-market-text217 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-market-component3 {
  text-decoration: none;
}
 
@media(max-width: 1200px) {
  .blog-staking-market-gatewaywirexcasestudy1 {
    width: 100%;
    height: auto;
  }
  .blog-staking-market-text103 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-staking-market-text108 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-staking-market-text114 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-staking-market-text133 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-staking-market-text139 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-staking-market-text146 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-staking-market-text154 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-staking-market-text161 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-staking-market-text173 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-staking-market-text186 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-staking-market-text190 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-staking-market-text202 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-staking-market-text217 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
}
 
@media(max-width: 991px) {
  .blog-staking-market-container2 {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
  }
}
 
@media(max-width: 767px) {
  .blog-staking-market-container2 {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
  }
}
 
@media(max-width: 550px) {
  .blog-staking-market-container2 {
    padding-top: var(--dl-layout-space-twounits);
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
    padding-bottom: var(--dl-layout-space-twounits);
  }
  .blog-staking-market-post {
    width: 100%;
  }
  .blog-staking-market-gatewaywirexcasestudy1 {
    height: auto;
  }
}
