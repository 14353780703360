.home-container10 {
  gap: var(--dl-layout-space-oneandhalfunits);
  width: 100%;
  display: flex;
  min-height: 0;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.home-highlight1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-hero {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.home-left-part {
  gap: var(--dl-layout-space-unit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-layout-space-threeunits);
  padding-left: var(--dl-layout-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-layout-space-threeunits);
}
 
.home-text100 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 64px;
  font-style: Black;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 900;
  line-height: 72px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-link1 {
  display: contents;
}
 
.home-button {
  gap: 24px;
  width: auto;
  height: auto;
  display: flex;
  padding: 13px 23px;
  box-shadow: 0px 4px 4px 0px rgba(137, 80, 250, 0.25) ;
  box-sizing: content-box;
  align-items: flex-start;
  border-color: rgba(137, 80, 250, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 32px;
  text-decoration: none;
  background-color: rgba(39, 48, 60, 1);
}
 
.home-text106 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  font-style: Black;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-trendingup {
  width: 24px;
  height: 24px;
}
 
.home-right-part {
  gap: 32px;
  width: auto;
  height: 100%;
  display: flex;
  padding: 40px 48px;
  overflow: visible;
  align-self: stretch;
  box-shadow: 0px 32px 20px -24px rgba(137, 80, 250, 0.25) ;
  margin-top: var(--dl-layout-space-threeunits);
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-frame1171275257 {
  gap: 16px;
  width: 100%;
  display: flex;
  postion: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame1171275256 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
 
.home-text107 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame482019 {
  gap: 8px;
  display: flex;
  padding: 4px 8px;
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  background-color: rgba(137, 80, 250, 1);
}
 
.home-text108 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame482204 {
  display: flex;
  padding: 16px;
  overflow: visible;
  align-self: stretch;
  box-shadow: 0px 12px 32px -20px rgba(137, 80, 250, 1) ;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-frame481578 {
  gap: 16px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
}
 
.home-frame11712752541 {
  gap: 16px;
  display: flex;
  align-items: center;
}
 
.home-logoethereum {
  width: 32px;
  height: 32px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
}
 
.home-image1 {
  width: 32px;
  object-fit: cover;
}
 
.home-frame481505 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame4815121 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
 
.home-text110 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700px;
  line-height: 24px;
  font-stretch: normal;
  letter-spacing: 0.15000000596046448px;
}
 
.home-text111 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 400px;
  line-height: 18px;
  font-stretch: normal;
  letter-spacing: 0.25px;
}
 
.home-frame1171275255 {
  gap: 16px;
  width: 219px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-end;
}
 
.home-input {
  width: 100%;
  font-size: 30px;
  font-family: "Gotham-Medium";
  border-color: #8950fa;
  border-style: solid;
  border-width: 2px;
  padding-left: var(--dl-layout-space-halfunit);
  border-radius: var(--dl-layout-radius-cardradius);
  background-color: var(--dl-color-default-secondarytext);
}
 
.home-container11 {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: flex-start;
}
 
.home-svg24iconchevron {
  width: 32px;
  height: 32px;
}
 
.home-container12 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px solid white;
  z-index: 100;
  box-shadow: 0px 12px 32px -20px rgba(137, 80, 250, 1) ;
  align-items: flex-start;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-text113 {
  display: inline-block;
}
 
.home-text114 {
  display: inline-block;
}
 
.home-text115 {
  display: inline-block;
}
 
.home-text116 {
  display: inline-block;
}
 
.home-text117 {
  display: inline-block;
}
 
.home-text118 {
  display: inline-block;
}
 
.home-text119 {
  display: inline-block;
}
 
.home-text120 {
  display: inline-block;
}
 
.home-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
 
.home-link2 {
  fill: var(--dl-color-default-maininteractive);
  color: var(--dl-color-default-maininteractive);
  margin-top: 10px;
  font-family: "Gotham-Medium";
  margin-left: auto;
  border-style: hidden;
  border-width: 0px;
  margin-right: 10px;
  border-radius: var(--dl-layout-radius-buttonradius);
  margin-bottom: 10px;
  text-decoration: none;
  background-color: #EDE5FE;
}
 
.home-line6 {
  width: 492px;
  height: 1px;
  opacity: 1;
  align-self: stretch;
}
 
.home-frame1171275258 {
  gap: var(--dl-layout-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-frame4815122 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
 
.home-text121 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 16px;
  font-style: normal;
  text-align: right;
  font-family: "Gotham-Medium";
  font-weight: 700px;
  line-height: 24px;
  font-stretch: normal;
  letter-spacing: 0.15px;
}
 
.home-text122 {
  color: rgb(0, 0, 0);
  font-size: 56px;
  font-family: "Gotham-Black";
  font-weight: 900;
  line-height: 64px;
  text-decoration: none;
}
 
.home-frame1171275238 {
  gap: 35px;
  width: 1416px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
 
.home-container14 {
  gap: 30px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
 
.home-frame10 {
  width: 121px;
  height: 32px;
}
 
.home-flare11 {
  width: 95px;
  height: 32px;
}
 
.home-frame11712751261 {
  width: 172px;
  height: 32px;
}
 
.home-frame11 {
  width: 300px;
  height: 32px;
}
 
.home-availlogo9c818c5a11 {
  width: 124px;
  height: 32px;
  opacity: 0.50;
}
 
.home-frame13 {
  width: 110px;
  height: 32px;
}
 
.home-frame14 {
  width: 121px;
  height: 32px;
}
 
.home-frame15 {
  width: 121px;
  height: 32px;
}
 
.home-frame16 {
  width: 121px;
  height: 32px;
}
 
.home-flare12 {
  width: 95px;
  height: 32px;
}
 
.home-frame11712751262 {
  width: 172px;
  height: 32px;
}
 
.home-frame17 {
  width: 300px;
  height: 32px;
}
 
.home-availlogo9c818c5a12 {
  width: 124px;
  height: 32px;
  opacity: 0.50;
}
 
.home-frame18 {
  width: 110px;
  height: 32px;
}
 
.home-frame19 {
  width: 121px;
  height: 32px;
}
 
.home-frame20 {
  width: 121px;
  height: 32px;
}
 
.home-container15 {
  gap: var(--dl-layout-space-twounits);
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: center;
  flex-direction: column;
}
 
.home-container16 {
  gap: var(--dl-layout-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: center;
  flex-direction: row;
}
 
.home-us-ps {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: flex-end;
}
 
.home-content {
  gap: 24px;
  display: flex;
  padding: 32px 56px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: 0px 32px 20px -24px rgba(137, 80, 250, 0.25) ;
  align-items: center;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-frame482119 {
  gap: var(--dl-layout-space-unit);
  width: auto;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-frame1171275281 {
  gap: 10px;
  height: auto;
  display: flex;
  padding: 16px;
  align-items: center;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-banking011 {
  width: 56px;
  height: 56px;
}
 
.home-frame482177 {
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.home-text123 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 16px;
  font-style: Book;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame482120 {
  gap: var(--dl-layout-space-unit);
  width: auto;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-frame1171275282 {
  gap: 10px;
  height: auto;
  display: flex;
  padding: 16px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-banking021 {
  width: 56px;
  height: 56px;
}
 
.home-frame482131 {
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.home-text127 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 16px;
  font-style: Book;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame482121 {
  gap: var(--dl-layout-space-unit);
  width: auto;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-frame1171275283 {
  gap: 10px;
  height: auto;
  display: flex;
  padding: 16px;
  align-items: center;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-banking231 {
  width: 56px;
  height: 56px;
}
 
.home-frame482176 {
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.home-text131 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 16px;
  font-style: Book;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-container17 {
  gap: var(--dl-layout-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: center;
  flex-direction: row;
}
 
.home-frame1171275274 {
  gap: 154px;
  width: 974px;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-layout-space-fourunits);
  padding-bottom: var(--dl-layout-space-fourunits);
}
 
.home-frame1171275273 {
  gap: 50px;
  width: 336px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text135 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 48px;
  align-self: stretch;
  font-style: Black;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 900;
  line-height: 56px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-link3 {
  display: contents;
}
 
.home-frame1171275243 {
  gap: 24px;
  display: flex;
  padding: 13px 23px;
  box-shadow: 0px 4px 4px 0px rgba(137, 80, 250, 0.25) ;
  box-sizing: content-box;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.05000000074505806);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 32px;
  text-decoration: none;
  background-color: rgba(39, 48, 60, 1);
}
 
.home-text136 {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 20px;
  font-style: Black;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-bullseye {
  width: 24px;
  height: 24px;
}
 
.home-text137 {
  color: rgba(0, 0, 0, 1);
  width: 484px;
  height: auto;
  font-size: 36px;
  font-style: Medium;
  text-align: right;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 48px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-highlight2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-container18 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-container19 {
  width: 100%;
  height: 64px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 32px 20px -24px rgba(137, 80, 250, 0.25) ;
  align-items: center;
  flex-shrink: 0;
  padding-left: var(--dl-layout-space-oneandhalfunits);
  border-radius: 16px;
  padding-right: var(--dl-layout-space-oneandhalfunits);
  justify-content: space-between;
  background-color: var(--dl-color-default-secondarytext);
}
 
.home-title {
  gap: 24px;
  width: auto;
  display: flex;
  align-items: center;
}
 
.home-image2 {
  width: auto;
  height: 32px;
  object-fit: cover;
}
 
.home-text138 {
  fill: var(--dl-color-default-text);
  color: var(--dl-color-default-text);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-container20 {
  gap: var(--dl-layout-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
}
 
.home-container21 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame11712752542 {
  width: 100%;
  height: 353px;
  display: flex;
  padding: 56px 48px;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  box-shadow: 0px 32px 20px -24px rgba(137, 80, 250, 0.25) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.home-text139 {
  color: var(--dl-color-default-maintext);
  height: auto;
  z-index: 1;
  font-size: 56px;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 700px;
  line-height: 1.15;
  font-stretch: normal;
  letter-spacing: 0.25px;
  text-transform: none;
  text-decoration: none;
}
 
.home-text140 {
  color: var(--dl-color-default-maintext);
  width: 100%;
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-image3 {
  left: -24px;
  width: 700px;
  bottom: -302px;
  position: absolute;
  transform: rotate(353deg);
  object-fit: cover;
}
 
.home-container22 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-layout-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-layout-space-fourunits);
  background-size: cover;
  background-image: url("/external/Stakeway/assets/line-1500w.png");
}
 
.home-frame1171275277 {
  gap: 0;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
}
 
.home-frame1171275275 {
  gap: 16px;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text141 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 48px;
  font-style: Black;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 900;
  line-height: 56px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-text145 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-navlink1 {
  display: contents;
}
 
.home-frame1171275247 {
  gap: 4px;
  display: flex;
  align-items: center;
  text-decoration: none;
}
 
.home-text146 {
  color: var(--dl-color-default-maininteractive);
  height: auto;
  font-size: 16px;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-chevronright {
  width: 24px;
  height: 24px;
}
 
.home-frame1171275276 {
  gap: 24px;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-navlink2 {
  display: contents;
}
 
.home-frame1171275248 {
  gap: 16px;
  display: flex;
  padding: 32px;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 0px 32px 20px -24px rgba(137, 80, 250, 0.25) ;
  transition: 0.3s;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  backdrop-filter: blur(20px);
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.home-frame1171275248:hover {
  color: var(--dl-color-default-maininteractive);
  cursor: pointer;
  box-shadow: 0px 32px 20px -12px rgba(137, 80, 250, 0.5) ;
}
 
.home-text147 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-navlink3 {
  display: contents;
}
 
.home-frame1171275249 {
  gap: 16px;
  display: flex;
  padding: 32px;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 0px 32px 20px -24px rgba(137, 80, 250, 0.25) ;
  transition: 0.3s;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  backdrop-filter: blur(20px);
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.home-frame1171275249:hover {
  color: var(--dl-color-default-maininteractive);
  cursor: pointer;
  box-shadow: 0px 32px 20px -18px rgba(137, 80, 250, 0.5) ;
}
 
.home-text148 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-container23 {
  gap: var(--dl-layout-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
}
 
.home-container24 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.home-frame1171275278 {
  gap: 20px;
  width: 458px;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  padding-left: var(--dl-layout-space-fiveunits);
  flex-direction: column;
}
 
.home-text149 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 48px;
  font-style: Black;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 900;
  line-height: 56px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-text150 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame4816321 {
  gap: 4px;
  width: 448px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-frame481630 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
 
.home-check1 {
  width: 24px;
  height: 24px;
}
 
.home-text151 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame481631 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
 
.home-check2 {
  width: 24px;
  height: 24px;
}
 
.home-text152 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame4816322 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
 
.home-check3 {
  width: 24px;
  height: 24px;
}
 
.home-text153 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-iframe {
  width: 450px;
  height: 840px;
}
 
.home-frame1171275096 {
  gap: 34px;
  width: 1400px;
  height: auto;
  display: flex;
  align-items: center;
}
 
.home-container25 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
 
.home-frame11712750641 {
  gap: 24px;
  width: 200px;
  display: flex;
  padding: 48px 24px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: none;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 59px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-frame410 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame810 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-frame11712751151 {
  gap: 10px;
  width: 90px;
  height: 90px;
  display: flex;
  position: relative;
  align-items: center;
}
 
.home-ethereumlogo201411 {
  top: 0px;
  left: 17.25px;
  width: 55px;
  height: 90px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-group3 {
  top: 0px;
  left: 0px;
  width: 55.25160598754883px;
  height: 89.9798355102539px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector10 {
  top: 0px;
  left: 27.014265060424805px;
  width: 28px;
  height: 62px;
  position: absolute;
}
 
.home-vector11 {
  top: 0px;
  left: 0px;
  width: 28px;
  height: 62px;
  position: absolute;
}
 
.home-vector12 {
  top: 51.06185531616211px;
  left: 27.27757453918457px;
  width: 28px;
  height: 39px;
  position: absolute;
}
 
.home-vector13 {
  top: 51.061553955078125px;
  left: 0px;
  width: 28px;
  height: 39px;
  position: absolute;
}
 
.home-vector14 {
  top: 33.271663665771484px;
  left: 27.617502212524414px;
  width: 28px;
  height: 29px;
  position: absolute;
}
 
.home-vector15 {
  top: 33.27168273925781px;
  left: 0.00019424459605943412px;
  width: 28px;
  height: 29px;
  position: absolute;
}
 
.home-group110 {
  width: 228px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text154 {
  color: rgba(0, 0, 0, 1);
  width: 228px;
  height: auto;
  position: absolute;
  font-size: 36px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-group210 {
  width: 100%;
  height: 24px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text155 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-line510 {
  width: 208px;
  height: 1px;
  align-self: stretch;
}
 
.home-text156 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame11712750631 {
  gap: 24px;
  width: 200px;
  display: flex;
  padding: 48px 24px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: none;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 59px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-frame411 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame811 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-fill11 {
  width: 90px;
  height: 90px;
}
 
.home-group111 {
  width: 228px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text157 {
  color: rgb(0, 0, 0);
  width: 100%;
  font-size: 36px;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  text-decoration: none;
}
 
.home-group211 {
  width: 100%;
  height: 24px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text158 {
  color: rgb(0, 0, 0);
  width: 100%;
  font-size: 20px;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
 
.home-line511 {
  width: 208px;
  height: 1px;
  align-self: stretch;
}
 
.home-text159 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame121 {
  gap: 24px;
  width: 200px;
  display: flex;
  padding: 48px 24px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: none;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 59px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-frame412 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame812 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-polygon1 {
  width: 90px;
  height: 90px;
}
 
.home-group112 {
  width: 228px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text160 {
  color: rgb(0, 0, 0);
  width: 228px;
  height: auto;
  position: absolute;
  font-size: 36px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-group212 {
  width: 100%;
  height: 24px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text161 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-line512 {
  width: 208px;
  height: 1px;
  align-self: stretch;
}
 
.home-text162 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame11712750621 {
  gap: 24px;
  width: 200px;
  display: flex;
  padding: 48px 24px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: none;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 59px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-frame413 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame813 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-gnosisgnognologo11 {
  width: 90px;
  height: 90px;
}
 
.home-group113 {
  width: 228px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text163 {
  color: rgba(0, 0, 0, 1);
  width: 228px;
  height: auto;
  position: absolute;
  font-size: 36px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-group213 {
  width: 100%;
  height: 24px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text164 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-line513 {
  width: 208px;
  height: 1px;
  align-self: stretch;
}
 
.home-text165 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame-stellar1 {
  gap: 24px;
  width: 200px;
  display: flex;
  padding: 48px 24px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: none;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 59px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-frame414 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame814 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-stellar1 {
  width: 90px;
  height: 90px;
}
 
.home-group114 {
  width: 228px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text166 {
  color: rgb(0, 0, 0);
  width: 228px;
  height: auto;
  position: absolute;
  font-size: 36px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-group214 {
  width: 100%;
  height: 24px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text167 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-line514 {
  width: 208px;
  height: 1px;
  align-self: stretch;
}
 
.home-text168 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame-avail1 {
  gap: 24px;
  width: 200px;
  display: flex;
  padding: 48px 24px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: none;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 59px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-frame415 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame815 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-avail1 {
  width: 90px;
  height: 90px;
}
 
.home-group115 {
  width: 228px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text169 {
  color: rgb(0, 0, 0);
  width: 228px;
  height: auto;
  position: absolute;
  font-size: 36px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-group215 {
  width: 100%;
  height: 24px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text170 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-line515 {
  width: 208px;
  height: 1px;
  align-self: stretch;
}
 
.home-text171 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame-flare1 {
  gap: 24px;
  width: 200px;
  padding: 48px 24px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: none;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 59px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-frame416 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame816 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-flare2 {
  width: 90px;
  height: 90px;
}
 
.home-group116 {
  width: 228px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text172 {
  color: rgb(0, 0, 0);
  width: 228px;
  height: auto;
  position: absolute;
  font-size: 36px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-group216 {
  width: 100%;
  height: 24px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text173 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-line516 {
  width: 208px;
  height: 1px;
  align-self: stretch;
}
 
.home-text174 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame-near1 {
  gap: 24px;
  width: 200px;
  display: flex;
  padding: 48px 24px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: none;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 59px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-frame417 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame817 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-near1 {
  width: 90px;
  height: 90px;
}
 
.home-group117 {
  width: 228px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text175 {
  color: rgb(0, 0, 0);
  width: 228px;
  height: auto;
  position: absolute;
  font-size: 36px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-group217 {
  width: 100%;
  height: 24px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text176 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-line517 {
  width: 208px;
  height: 1px;
  align-self: stretch;
}
 
.home-text177 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame11712750642 {
  gap: 24px;
  width: 200px;
  display: flex;
  padding: 48px 24px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: none;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 59px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-frame418 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame818 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-frame11712751152 {
  gap: 10px;
  width: 90px;
  height: 90px;
  display: flex;
  position: relative;
  align-items: center;
}
 
.home-ethereumlogo201412 {
  top: 0px;
  left: 17.25px;
  width: 55px;
  height: 90px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-group4 {
  top: 0px;
  left: 0px;
  width: 55.25160598754883px;
  height: 89.9798355102539px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector16 {
  top: 0px;
  left: 27.014265060424805px;
  width: 28px;
  height: 62px;
  position: absolute;
}
 
.home-vector17 {
  top: 0px;
  left: 0px;
  width: 28px;
  height: 62px;
  position: absolute;
}
 
.home-vector18 {
  top: 51.06185531616211px;
  left: 27.27757453918457px;
  width: 28px;
  height: 39px;
  position: absolute;
}
 
.home-vector19 {
  top: 51.061553955078125px;
  left: 0px;
  width: 28px;
  height: 39px;
  position: absolute;
}
 
.home-vector20 {
  top: 33.271663665771484px;
  left: 27.617502212524414px;
  width: 28px;
  height: 29px;
  position: absolute;
}
 
.home-vector21 {
  top: 33.27168273925781px;
  left: 0.00019424459605943412px;
  width: 28px;
  height: 29px;
  position: absolute;
}
 
.home-group118 {
  width: 228px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text178 {
  color: rgba(0, 0, 0, 1);
  width: 228px;
  height: auto;
  position: absolute;
  font-size: 36px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-group218 {
  width: 100%;
  height: 24px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text179 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-line518 {
  width: 208px;
  height: 1px;
  align-self: stretch;
}
 
.home-text180 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame11712750632 {
  gap: 24px;
  width: 200px;
  display: flex;
  padding: 48px 24px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: none;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 59px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-frame419 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame819 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-fill12 {
  width: 90px;
  height: 90px;
}
 
.home-group119 {
  width: 228px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text181 {
  color: rgb(0, 0, 0);
  width: 100%;
  font-size: 36px;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  text-decoration: none;
}
 
.home-group219 {
  width: 100%;
  height: 24px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text182 {
  color: rgb(0, 0, 0);
  width: 100%;
  font-size: 20px;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
 
.home-line519 {
  width: 208px;
  height: 1px;
  align-self: stretch;
}
 
.home-text183 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame122 {
  gap: 24px;
  width: 200px;
  display: flex;
  padding: 48px 24px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: none;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 59px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-frame420 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame820 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-polygon2 {
  width: 90px;
  height: 90px;
}
 
.home-group120 {
  width: 228px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text184 {
  color: rgb(0, 0, 0);
  width: 228px;
  height: auto;
  position: absolute;
  font-size: 36px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-group220 {
  width: 100%;
  height: 24px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text185 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-line520 {
  width: 208px;
  height: 1px;
  align-self: stretch;
}
 
.home-text186 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame11712750622 {
  gap: 24px;
  width: 200px;
  display: flex;
  padding: 48px 24px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: none;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 59px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-frame421 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame821 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-gnosisgnognologo12 {
  width: 90px;
  height: 90px;
}
 
.home-group121 {
  width: 228px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text187 {
  color: rgba(0, 0, 0, 1);
  width: 228px;
  height: auto;
  position: absolute;
  font-size: 36px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-group221 {
  width: 100%;
  height: 24px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text188 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-line521 {
  width: 208px;
  height: 1px;
  align-self: stretch;
}
 
.home-text189 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame-stellar2 {
  gap: 24px;
  width: 200px;
  display: flex;
  padding: 48px 24px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: none;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 59px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-frame422 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame822 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-stellar2 {
  width: 90px;
  height: 90px;
}
 
.home-group122 {
  width: 228px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text190 {
  color: rgb(0, 0, 0);
  width: 228px;
  height: auto;
  position: absolute;
  font-size: 36px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-group222 {
  width: 100%;
  height: 24px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text191 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-line522 {
  width: 208px;
  height: 1px;
  align-self: stretch;
}
 
.home-text192 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame-avail2 {
  gap: 24px;
  width: 200px;
  display: flex;
  padding: 48px 24px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: none;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 59px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-frame423 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame823 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-avail2 {
  width: 90px;
  height: 90px;
}
 
.home-group123 {
  width: 228px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text193 {
  color: rgb(0, 0, 0);
  width: 228px;
  height: auto;
  position: absolute;
  font-size: 36px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-group223 {
  width: 100%;
  height: 24px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text194 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-line523 {
  width: 208px;
  height: 1px;
  align-self: stretch;
}
 
.home-text195 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame-flare2 {
  gap: 24px;
  width: 200px;
  padding: 48px 24px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: none;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 59px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-frame424 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame824 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-flare3 {
  width: 90px;
  height: 90px;
}
 
.home-group124 {
  width: 228px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text196 {
  color: rgb(0, 0, 0);
  width: 228px;
  height: auto;
  position: absolute;
  font-size: 36px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-group224 {
  width: 100%;
  height: 24px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text197 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-line524 {
  width: 208px;
  height: 1px;
  align-self: stretch;
}
 
.home-text198 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame-near2 {
  gap: 24px;
  width: 200px;
  display: flex;
  padding: 48px 24px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: none;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 59px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-frame425 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame825 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-near2 {
  width: 90px;
  height: 90px;
}
 
.home-group125 {
  width: 228px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text199 {
  color: rgb(0, 0, 0);
  width: 228px;
  height: auto;
  position: absolute;
  font-size: 36px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-group225 {
  width: 100%;
  height: 24px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-text200 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-line525 {
  width: 208px;
  height: 1px;
  align-self: stretch;
}
 
.home-text201 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame1171275280 {
  gap: 38px;
  width: 100%;
  height: auto;
  display: none;
  max-width: 1200px;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-layout-space-threeunits);
  flex-direction: column;
}
 
.home-text202 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 48px;
  align-self: stretch;
  font-style: Black;
  text-align: center;
  font-family: "Gotham-Black";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame1171275279 {
  gap: 16px;
  display: flex;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-frame1171275098 {
  gap: 497px;
  display: flex;
  padding: 16px 24px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 30px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-text203 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: left;
  font-family: Gotham;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-chevron-left1 {
  width: 24px;
  height: 24px;
}
 
.home-frame1171275099 {
  gap: 497px;
  display: flex;
  padding: 16px 24px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 30px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-text204 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: left;
  font-family: Gotham;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-chevron-left2 {
  width: 24px;
  height: 24px;
}
 
.home-frame1171275100 {
  gap: 497px;
  display: flex;
  padding: 16px 24px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 30px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.home-text205 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: left;
  font-family: Gotham;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-chevron-left3 {
  width: 24px;
  height: 24px;
}
 
@media(max-width: 550px) {
  .home-container10 {
    overflow: hidden;
  }
  .home-highlight1 {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
  }
  .home-hero {
    flex-direction: column;
  }
  .home-left-part {
    width: 100%;
    padding-top: var(--dl-layout-space-halfunit);
    padding-left: var(--dl-layout-space-unit);
    padding-bottom: var(--dl-layout-space-halfunit);
  }
  .home-text100 {
    font-size: 56px;
    line-height: 64px;
  }
  .home-right-part {
    margin-top: var(--dl-layout-space-halfunit);
    margin-left: var(--dl-layout-space-unit);
    padding-top: var(--dl-layout-space-oneandhalfunits);
    margin-right: var(--dl-layout-space-unit);
    padding-left: var(--dl-layout-space-oneandhalfunits);
    padding-right: var(--dl-layout-space-oneandhalfunits);
  }
  .home-text107 {
    font-family: Gotham-Medium;
  }
  .home-frame481578 {
    flex-direction: column;
  }
  .home-frame11712752541 {
    width: 100%;
    justify-content: flex-start;
  }
  .home-frame1171275255 {
    width: 100%;
  }
  .home-text121 {
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-style: normal;
    font-family: Gotham-Medium;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  .home-text122 {
    width: 100%;
    font-size: 40px;
    text-align: left;
    line-height: 48px;
  }
  .home-frame1171275238 {
    gap: 24px;
    width: 100%;
    padding: 24px 24px;
    text-align: center;
    align-content: center;
    justify-content: center;
  }
  .home-container16 {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
    flex-direction: column;
  }
  .home-us-ps {
    width: 100%;
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
  }
  .home-content {
    width: 100%;
    flex-direction: column;
  }
  .home-frame482119 {
    flex-direction: column;
  }
  .home-banking011 {
    width: var(--dl-layout-size-large);
    height: var(--dl-layout-size-large);
  }
  .home-banking021 {
    width: var(--dl-layout-size-large);
    height: var(--dl-layout-size-large);
  }
  .home-banking231 {
    width: var(--dl-layout-size-large);
    height: var(--dl-layout-size-large);
  }
  .home-container17 {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
    flex-direction: column;
  }
  .home-frame1171275274 {
    gap: var(--dl-layout-space-fourunits);
    width: 100%;
    padding-left: var(--dl-layout-space-oneandhalfunits);
    padding-right: var(--dl-layout-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-text137 {
    width: 100%;
  }
  .home-highlight2 {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
  }
  .home-container19 {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
    justify-content: space-between;
  }
  .home-title {
    gap: var(--dl-layout-space-halfunit);
  }
  .home-text138 {
    color: var(--dl-color-default-text);
    font-size: 18px;
    font-family: Gotham-Bold;
    font-weight: 700;
    line-height: 24px;
    text-decoration: none;
  }
  .home-container20 {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
    flex-direction: column;
  }
  .home-container21 {
    width: 100%;
  }
  .home-frame11712752542 {
    width: 100%;
    padding-top: var(--dl-layout-space-twounits);
    padding-left: var(--dl-layout-space-oneandhalfunits);
    padding-right: var(--dl-layout-space-oneandhalfunits);
    padding-bottom: var(--dl-layout-space-twounits);
  }
  .home-text139 {
    font-size: 48px;
  }
  .home-text140 {
    width: 100%;
  }
  .home-container22 {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
    background-size: auto;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  .home-frame1171275277 {
    width: 100%;
    flex-direction: column;
  }
  .home-frame1171275275 {
    width: 100%;
    padding-bottom: var(--dl-layout-space-twounits);
  }
  .home-frame1171275276 {
    width: 100%;
  }
  .home-container23 {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
    flex-direction: column;
  }
  .home-container24 {
    gap: 24px;
    flex-direction: column;
    justify-content: unset;
  }
  .home-frame1171275278 {
    width: 100%;
    padding-top: var(--dl-layout-space-fiveunits);
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
  }
  .home-text149 {
    color: rgb(0, 0, 0);
    font-size: 48px;
    font-family: Gotham-Black;
    font-weight: 900;
    line-height: 56px;
    text-decoration: none;
  }
  .home-iframe {
    width: 100%;
    height: 680px;
    min-width: 375px;
  }
  .home-frame1171275096 {
    padding-top: var(--dl-layout-space-twounits);
    padding-left: var(--dl-layout-space-oneandhalfunits);
    padding-right: var(--dl-layout-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-group212 {
    width: 100%;
  }
  .home-text161 {
    width: 100%;
  }
  .home-group220 {
    width: 100%;
  }
  .home-text185 {
    width: 100%;
  }
  .home-frame1171275279 {
    width: 100%;
  }
  .home-text203 {
    font-family: Gotham-Bold;
  }
  .home-text204 {
    font-family: Gotham-Bold;
  }
  .home-text205 {
    font-family: Gotham-Bold;
  }
}
