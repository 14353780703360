.terms-of-service-container1 {
  gap: var(--dl-layout-space-unit);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.terms-of-service-content {
  gap: var(--dl-layout-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: center;
  flex-direction: column;
}
 
.terms-of-service-hero {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
 
.terms-of-service-description {
  gap: var(--dl-layout-space-halfunit);
  width: 421px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.terms-of-service-container2 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.terms-of-service-text100 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  font-size: 14px;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 700px;
  line-height: 18px;
  font-stretch: normal;
}
 
.terms-of-service-container3 {
  gap: var(--dl-layout-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-layout-space-sixunits);
  padding-right: var(--dl-layout-space-sixunits);
  flex-direction: column;
}
 
.terms-of-service-container4 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.terms-of-service-text101 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300px;
  line-height: 28px;
  font-stretch: normal;
  letter-spacing: 0.5px;
}
 
.terms-of-service-link {
  color: var(--dl-color-default-maininteractive);
  text-decoration: underline;
}
 
@media(max-width: 1200px) {
  .terms-of-service-container1 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
 
@media(max-width: 550px) {
  .terms-of-service-container1 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .terms-of-service-content {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
  }
  .terms-of-service-description {
    width: 100%;
  }
  .terms-of-service-container3 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
