.blog-post-regular-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.blog-post-regular-container2 {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.blog-post-regular-devnet1 {
  width: 100%;
  height: auto;
  align-self: stretch;
  border-radius: 30px;
}
 
.blog-post-regular-texts {
  gap: 8px;
  display: flex;
  padding: 16px;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.blog-post-regular-text1 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-post-regular-text2 {
  color: var(--dl-color-default-maintext);
  width: 407px;
  height: auto;
  font-size: 16px;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-post-regularroot-class-name {
  width: 50%;
}
 
.blog-post-regularroot-class-name1 {
  width: 50%;
}
 
@media(max-width: 550px) {
  .blog-post-regularroot-class-name {
    width: 100%;
  }
  .blog-post-regularroot-class-name1 {
    width: 100%;
  }
}
