.title-side-page-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.title-side-page-text {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  font-size: 72px;
  font-style: Black;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 900;
  line-height: 72px;
  font-stretch: normal;
  text-decoration: none;
}
 
.title-side-pageroot-class-name {
  max-width: 700px;
}
 
.title-side-pageroot-class-name1 {
  max-width: 700px;
}
 
@media(max-width: 1200px) {
  .title-side-page-text {
    color: rgb(0, 0, 0);
    font-size: 72px;
    font-family: Gotham-Black;
    font-weight: 900;
    line-height: 72px;
    text-decoration: none;
  }
  .title-side-pageroot-class-name {
    fill: var(--dl-color-theme-neutral-dark);
    color: var(--dl-color-theme-neutral-dark);
  }
}
 
@media(max-width: 550px) {
  .title-side-page-text {
    font-size: 56px;
    line-height: 56px;
  }
}
