.option-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.option-container2 {
  cursor: pointer;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
 
.option-logopolygon {
  width: 32px;
  height: 32px;
}
 
.option-texts {
  height: auto;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.option-text1 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.option-text2 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.option-radiobutton {
  display: none;
}
 
@media(max-width: 550px) {
  .option-container2 {
    gap: var(--dl-layout-space-unit);
    width: 100%;
    height: auto;
    flex-direction: row;
  }
  .option-texts {
    flex: 1;
    width: auto;
  }
  .option-text2 {
    width: auto;
  }
}
