.asset-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.asset-container2 {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 0px 24px 20px -24px rgba(137, 80, 250, 0.25) ;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: var(--dl-layout-space-unit);
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  padding-left: var(--dl-layout-space-oneandhalfunits);
  border-radius: 29px;
  padding-right: var(--dl-layout-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-layout-space-unit);
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.asset-content {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
 
.asset-name {
  gap: 30px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
 
.asset-logoethereum {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
}
 
.asset-image {
  width: 47px;
  height: 47px;
  object-fit: cover;
}
 
.asset-frame1171275268 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.asset-text10 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 16px;
  font-style: Bold;
  text-align: left;
  font-family: Gotham;
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.asset-text11 {
  color: rgba(0, 0, 0, 0.5);
  height: auto;
  font-size: 16px;
  font-style: Bold;
  text-align: left;
  font-family: Gotham;
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.asset-container3 {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
 
.asset-price {
  gap: 4px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
 
.asset-text12 {
  color: var(--dl-color-default-maintext);
  opacity: 0.5;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none;
}
 
.asset-text13 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
}
 
.asset-text14 {
  color: var(--dl-color-default-pink);
  width: 100%;
  height: auto;
  font-size: 14px;
  font-style: Bold;
  text-align: left;
  font-family: Gotham;
  font-weight: 700;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
 
.asset-market-cap {
  gap: 4px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
 
.asset-text15 {
  color: var(--dl-color-default-maintext);
  opacity: 0.5;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none;
}
 
.asset-text16 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
}
 
.asset-text17 {
  color: var(--dl-color-default-pink);
  width: 100%;
  height: auto;
  font-size: 14px;
  font-style: Bold;
  text-align: left;
  font-family: Gotham;
  font-weight: 700;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
 
.asset-container4 {
  flex: 1.5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
 
.asset-reward {
  gap: 4px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
 
.asset-text18 {
  color: var(--dl-color-default-maintext);
  opacity: 0.5;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none;
}
 
.asset-text19 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
}
 
.asset-text20 {
  color: var(--dl-color-default-green);
  width: 100%;
  height: auto;
  font-size: 14px;
  font-style: Bold;
  text-align: left;
  font-family: Gotham;
  font-weight: 700;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
 
.asset-action {
  gap: 16px;
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  box-shadow: 0px 4px 4px 0px rgba(137, 80, 250, 0.25) ;
  box-sizing: content-box;
  align-items: center;
  border-color: rgba(137, 80, 250, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 32px;
  background-color: rgba(39, 48, 60, 1);
}
 
.asset-text21 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16;
  font-style: Black;
  text-align: left;
  font-family: Gotham;
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.asset-vector {
  width: 13px;
  height: 8px;
}
 

 

 

 

 
@media(max-width: 550px) {
  .asset-content {
    gap: 16px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: unset;
  }
  .asset-name {
    gap: var(--dl-layout-space-unit);
  }
  .asset-container3 {
    width: 100%;
  }
  .asset-price {
    width: auto;
  }
  .asset-market-cap {
    width: auto;
  }
  .asset-container4 {
    width: 100%;
    align-items: flex-end;
  }
  .asset-reward {
    width: auto;
  }
  .assetroot-class-name {
    margin-left: var(--dl-layout-space-oneandhalfunits);
    margin-right: var(--dl-layout-space-oneandhalfunits);
  }
  .assetroot-class-name1 {
    margin-left: var(--dl-layout-space-oneandhalfunits);
    margin-right: var(--dl-layout-space-oneandhalfunits);
  }
  .assetroot-class-name2 {
    margin-left: var(--dl-layout-space-oneandhalfunits);
    margin-right: var(--dl-layout-space-oneandhalfunits);
  }
  .assetroot-class-name3 {
    margin-left: var(--dl-layout-space-oneandhalfunits);
    margin-right: var(--dl-layout-space-oneandhalfunits);
  }
}
