.button-small-main-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.button-small-mainroot-class-name {
  width: auto;
}
 
.button-small-mainroot-class-name1 {
  width: auto;
}
 
.button-small-mainroot-class-name2 {
  width: auto;
}
 
.button-small-mainroot-class-name3 {
  width: auto;
}
 
.button-small-mainroot-class-name4 {
  width: auto;
}
 
.button-small-mainroot-class-name5 {
  width: auto;
}
 
.button-small-mainroot-class-name6 {
  width: auto;
}
