.thank-you-container1 {
  gap: var(--dl-layout-space-unit);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.thank-you-content {
  gap: var(--dl-layout-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-layout-space-fiveunits);
}
 
.thank-you-hero {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.thank-you-description {
  gap: var(--dl-layout-space-twounits);
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.thank-you-container2 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.thank-you-text1 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  font-size: 40px;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 700px;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.thank-you-container3 {
  gap: var(--dl-layout-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.thank-you-form {
  gap: var(--dl-layout-space-oneandhalfunits);
  width: 50%;
  height: auto;
  display: flex;
  padding-top: var(--dl-layout-space-oneandhalfunits);
  padding-left: var(--dl-layout-space-twounits);
  border-radius: 29px;
  padding-right: var(--dl-layout-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-layout-space-oneandhalfunits);
  background-color: var(--dl-color-default-secondarytext);
}
 
.thank-you-step5 {
  gap: var(--dl-layout-space-unit);
  flex-direction: column;
}
 
.thank-you-frame482128 {
  gap: 16px;
  height: auto;
  display: flex;
  padding: 64px 0 0;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.thank-you-frame482129 {
  width: 128px;
  height: 128px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.thank-you-ellipse4 {
  top: 0px;
  left: 0px;
  width: 128px;
  height: 128px;
  position: absolute;
}
 
.thank-you-svg24iconcheckmark {
  top: 32px;
  left: 32px;
  width: 64px;
  height: 64px;
  position: absolute;
}
 
.thank-you-text2 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.thank-you-text6 {
  color: rgb(0, 0, 0);
  width: 378px;
  height: auto;
  font-size: 16px;
  font-style: Book;
  text-align: center;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
@media(max-width: 1200px) {
  .thank-you-container1 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
 
@media(max-width: 550px) {
  .thank-you-content {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
  }
  .thank-you-hero {
    gap: var(--dl-layout-space-oneandhalfunits);
    flex-direction: column;
    justify-content: unset;
  }
  .thank-you-description {
    width: 100%;
  }
  .thank-you-text1 {
    font-size: 44px;
  }
  .thank-you-form {
    width: 100%;
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
  }
  .thank-you-frame482128 {
    padding-left: var(--dl-layout-space-oneandhalfunits);
    padding-right: var(--dl-layout-space-oneandhalfunits);
  }
  .thank-you-text6 {
    width: 100%;
  }
}
