.stakeway-card-reverse-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.stakeway-card-reverse-frame1171275254 {
  width: 100%;
  height: 353px;
  display: flex;
  padding: 56px 48px;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  box-shadow: 0px 32px 20px -24px rgba(137, 80, 250, 0.25) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.stakeway-card-reverse-text1 {
  color: var(--dl-color-default-maintext);
  height: auto;
  z-index: 1;
  font-size: 56px;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 700px;
  line-height: 1.15;
  font-stretch: normal;
  letter-spacing: 0.25px;
  text-transform: none;
  text-decoration: none;
}
 
.stakeway-card-reverse-text2 {
  color: var(--dl-color-default-maintext);
  width: 50%;
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.stakeway-card-reverse-image {
  top: 30%;
  left: 50%;
  width: 300px;
  position: absolute;
  object-fit: cover;
}
 
@media(max-width: 550px) {
  .stakeway-card-reverse-container {
    width: 100%;
  }
  .stakeway-card-reverse-frame1171275254 {
    width: 100%;
    padding-top: var(--dl-layout-space-twounits);
    padding-left: var(--dl-layout-space-oneandhalfunits);
    padding-right: var(--dl-layout-space-oneandhalfunits);
    padding-bottom: var(--dl-layout-space-twounits);
  }
  .stakeway-card-reverse-text2 {
    width: 100%;
  }
  .stakeway-card-reverse-image {
    top: 40%;
  }
}
