.button-primary-subtitle-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
}
 

 
.button-primary-subtitle-container2 {
  width: auto;
  cursor: pointer;
  height: auto;
  display: flex;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 0px 12px 32px -20px rgba(51, 11, 129, 1) ;
  transition: 0.3s;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-layout-space-unit);
  padding-left: 8px 24px;
  border-radius: 20px;
  padding-right: 8px 24px;
  padding-bottom: var(--dl-layout-space-unit);
  background-color: rgba(137, 80, 250, 1);
}
 
.button-primary-subtitle-container2:hover {
  background-color: #6217f9;
}
 
.button-primary-subtitle-texts {
  width: auto;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.button-primary-subtitle-text1 {
  color: rgb(255, 255, 255);
  width: auto;
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.button-primary-subtitle-text2 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Book;
  text-align: center;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.button-primary-subtitleroot-class-name {
  width: 100%;
  max-width: var(--dl-layout-size-xxlarge);
}
 
.button-primary-subtitleroot-class-name1 {
  max-width: 390px;
}
 
.button-primary-subtitleroot-class-name2 {
  max-width: 390px;
}
 
.button-primary-subtitleroot-class-name3 {
  max-width: 390px;
}
 
.button-primary-subtitleroot-class-name4 {
  max-width: 390px;
}
 
@media(max-width: 1200px) {
  .button-primary-subtitleroot-class-name {
    min-width: var(--dl-layout-size-xxlarge);
  }
}
 
@media(max-width: 550px) {
  .button-primary-subtitleroot-class-name {
    width: 100%;
    max-width: 100%;
  }
}
