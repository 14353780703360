.hero-career-description-description {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.hero-career-description-container {
  gap: var(--dl-layout-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.hero-career-description-text1 {
  color: rgba(0, 0, 0, 1);
  width: auto;
  height: auto;
  font-size: 56px;
  align-self: stretch;
  font-style: Ultra;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 400;
  line-height: 56px;
  font-stretch: normal;
  text-transform: uppercase;
  text-decoration: none;
}
 
.hero-career-description-parameter1 {
  gap: 4px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.hero-career-description-text2 {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
 
.hero-career-description-text3 {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.hero-career-description-parameter2 {
  gap: 4px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.hero-career-description-text4 {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
 
.hero-career-description-text5 {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.hero-career-description-parameter3 {
  gap: 4px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.hero-career-description-text6 {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
 
.hero-career-description-text7 {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.hero-career-descriptionroot-class-name {
  flex: 1;
  width: auto;
}
 
.hero-career-descriptionroot-class-name1 {
  flex: 1;
  width: auto;
}
 
.hero-career-descriptionroot-class-name2 {
  flex: 1;
  width: auto;
}
 
.hero-career-descriptionroot-class-name3 {
  flex: 1;
  width: auto;
}
 
@media(max-width: 550px) {
  .hero-career-description-text1 {
    font-size: 40px;
    line-height: 48px;
  }
  .hero-career-descriptionroot-class-name {
    width: 100%;
  }
  .hero-career-descriptionroot-class-name2 {
    width: 100%;
  }
  .hero-career-descriptionroot-class-name3 {
    width: 100%;
  }
}
