.page-container {
  gap: var(--dl-layout-space-unit);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.page-content {
  gap: var(--dl-layout-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-layout-space-fiveunits);
}
 
.page-hero {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.page-description {
  gap: var(--dl-layout-space-halfunit);
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.page-image {
  width: 50%;
  object-fit: cover;
}
 
@media(max-width: 1200px) {
  .page-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}
 
@media(max-width: 550px) {
  .page-content {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
  }
  .page-hero {
    flex-direction: column-reverse;
  }
  .page-description {
    width: 100%;
  }
  .page-image {
    width: 100%;
  }
}
