.blog-staking-security-container1 {
  gap: var(--dl-layout-space-twounits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.blog-staking-security-container2 {
  gap: var(--dl-layout-space-twounits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: flex-start;
  padding-top: var(--dl-layout-space-threeunits);
  flex-direction: column;
}
 
.blog-staking-security-post {
  gap: 32px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.blog-staking-security-gatewaywirexcasestudy1 {
  width: 100%;
  height: auto;
  align-self: stretch;
  border-radius: 30px;
}
 
.blog-staking-security-text10 {
  gap: 32px;
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.blog-staking-security-container3 {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.blog-staking-security-tags {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
 
.blog-staking-security-text11 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-security-text14 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-security-text15 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-security-text21 {
  color: var(--dl-color-default-maintext);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 28px;
  text-decoration: none;
}
 
.blog-staking-security-text23 {
  color: var(--dl-color-default-maintext);
  line-height: 28px;
  text-decoration: none;
}
 
.blog-staking-security-text24 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-security-text30 {
  color: var(--dl-color-default-maintext);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 28px;
  text-decoration: none;
}
 
.blog-staking-security-text32 {
  color: var(--dl-color-default-maintext);
  line-height: 28px;
  text-decoration: none;
}
 
.blog-staking-security-text33 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-security-text39 {
  color: var(--dl-color-default-maintext);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 28px;
  text-decoration: none;
}
 
.blog-staking-security-text41 {
  color: var(--dl-color-default-maintext);
  line-height: 28px;
  text-decoration: none;
}
 
.blog-staking-security-text42 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-security-text48 {
  color: var(--dl-color-default-maintext);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 28px;
  text-decoration: none;
}
 
.blog-staking-security-text50 {
  color: var(--dl-color-default-maintext);
  line-height: 28px;
  text-decoration: none;
}
 
.blog-staking-security-text51 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-security-text60 {
  color: var(--dl-color-default-maintext);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 28px;
  text-decoration: none;
}
 
.blog-staking-security-text62 {
  color: var(--dl-color-default-maintext);
  line-height: 28px;
  text-decoration: none;
}
 
.blog-staking-security-text63 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-security-text69 {
  color: var(--dl-color-default-maintext);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 28px;
  text-decoration: none;
}
 
.blog-staking-security-text71 {
  color: var(--dl-color-default-maintext);
  line-height: 28px;
  text-decoration: none;
}
 
.blog-staking-security-text72 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-staking-security-component3 {
  text-decoration: none;
}
 
@media(max-width: 1200px) {
  .blog-staking-security-gatewaywirexcasestudy1 {
    width: 100%;
    height: auto;
  }
  .blog-staking-security-text15 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-staking-security-text24 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-staking-security-text33 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-staking-security-text42 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-staking-security-text51 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-staking-security-text63 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-staking-security-text72 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
}
 
@media(max-width: 991px) {
  .blog-staking-security-container2 {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
  }
}
 
@media(max-width: 767px) {
  .blog-staking-security-container2 {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
  }
}
 
@media(max-width: 550px) {
  .blog-staking-security-container2 {
    padding-top: var(--dl-layout-space-twounits);
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
    padding-bottom: var(--dl-layout-space-twounits);
  }
  .blog-staking-security-post {
    width: 100%;
  }
  .blog-staking-security-gatewaywirexcasestudy1 {
    height: auto;
  }
}
