.careers-container1 {
  gap: var(--dl-layout-space-twounits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.careers-container2 {
  gap: var(--dl-layout-space-twounits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: flex-start;
  padding-top: var(--dl-layout-space-threeunits);
  flex-direction: column;
}
 
.careers-title {
  gap: 277px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 64px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
 
.careers-subtext {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.careers-text1 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 56px;
  align-self: stretch;
  font-style: Ultra;
  text-align: center;
  font-family: "Gotham-Black";
  font-weight: 400;
  line-height: 56px;
  font-stretch: normal;
  text-decoration: none;
}
 
.careers-text2 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: Gotham;
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.careers-navlink1 {
  display: contents;
}
 
.careers-component2 {
  text-decoration: none;
}
 
.careers-navlink2 {
  display: contents;
}
 
.careers-component3 {
  text-decoration: none;
}
 
@media(max-width: 991px) {
  .careers-container2 {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
  }
}
 
@media(max-width: 767px) {
  .careers-container2 {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
  }
}
 
@media(max-width: 550px) {
  .careers-container2 {
    padding-top: var(--dl-layout-space-twounits);
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
    padding-bottom: var(--dl-layout-space-twounits);
  }
}
