.career-career {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: flex-end;
}
 
.career-container {
  gap: 24px;
  display: flex;
  padding: 32px 56px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: 0px 8px 24px -20px rgba(127, 127, 127, 0.5) ;
  transition: 0.3s;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.career-container:hover {
  box-shadow: 0px 8px 24px -20px rgba(137, 80, 250, 0.8);
}
 
.career-texts {
  gap: 8px;
  width: 958px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.career-tags {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
 
.career-title {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.career-text1 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 32px;
  font-style: Bold;
  text-align: left;
  font-family: Gotham;
  font-weight: 700;
  line-height: 48px;
  font-stretch: normal;
  text-decoration: none;
}
 
.career-text2 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Gotham;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.career-arrowright {
  width: 32px;
  height: 32px;
}
 
@media(max-width: 550px) {
  .career-container {
    padding-left: var(--dl-layout-space-oneandhalfunits);
    padding-right: var(--dl-layout-space-oneandhalfunits);
  }
  .career-texts {
    width: 100%;
  }
  .career-tags {
    width: 100%;
  }
  .career-text1 {
    width: 100%;
    font-family: Gotham-Bold;
  }
  .career-text2 {
    font-family: Gotham-Medium;
  }
  .career-arrowright {
    display: none;
  }
}
