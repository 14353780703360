.stakeway-footer-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.stakeway-footer-container2 {
  gap: 48px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 48px 32px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
 
.stakeway-footer-content {
  gap: var(--dl-layout-space-fourunits);
  display: flex;
  align-items: flex-start;
}
 
.stakeway-footer-column1 {
  gap: 32px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.stakeway-footer-products {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.stakeway-footer-text1 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 30px;
  font-stretch: normal;
  text-decoration: none;
}
 
.stakeway-footer-component10 {
  text-decoration: none;
}
 
.stakeway-footer-component11 {
  text-decoration: none;
}
 
.stakeway-footer-component12 {
  text-decoration: none;
}
 
.stakeway-footer-company {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.stakeway-footer-text2 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 30px;
  font-stretch: normal;
  text-decoration: none;
}
 
.stakeway-footer-component13 {
  text-decoration: none;
}
 
.stakeway-footer-component14 {
  text-decoration: none;
}
 
.stakeway-footer-component15 {
  text-decoration: none;
}
 
.stakeway-footer-component16 {
  text-decoration: none;
}
 
.stakeway-footer-component17 {
  text-decoration: none;
}
 
.stakeway-footer-component18 {
  text-decoration: none;
}
 
.stakeway-footer-column2 {
  gap: 32px;
  width: 360px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.stakeway-footer-developers {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.stakeway-footer-text3 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 30px;
  font-stretch: normal;
  text-decoration: none;
}
 
.stakeway-footer-component19 {
  text-decoration: none;
}
 
.stakeway-footer-component20 {
  text-decoration: none;
}
 
.stakeway-footer-component21 {
  text-decoration: none;
}
 
.stakeway-footer-socials {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.stakeway-footer-link1 {
  display: contents;
}
 
.stakeway-footer-medium {
  width: 36px;
  height: 36px;
  transition: 0.3s;
  text-decoration: none;
}
 
.stakeway-footer-medium:hover {
  filter: brightness(0) saturate(100%) invert(19%) sepia(86%) saturate(7464%) hue-rotate(251deg) brightness(95%) contrast(104%);
}
 
.stakeway-footer-link2 {
  display: contents;
}
 
.stakeway-footer-twitter-x {
  width: 36px;
  height: 36px;
  transition: 0.3s;
  text-decoration: none;
}
 
.stakeway-footer-twitter-x:hover {
  filter: brightness(0) saturate(100%) invert(19%) sepia(86%) saturate(7464%) hue-rotate(251deg) brightness(95%) contrast(104%);
}
 
.stakeway-footer-link3 {
  display: contents;
}
 
.stakeway-footer-linked-in {
  width: 36px;
  height: 36px;
  transition: 0.3s;
  text-decoration: none;
}
 
.stakeway-footer-linked-in:hover {
  filter: brightness(0) saturate(100%) invert(19%) sepia(86%) saturate(7464%) hue-rotate(251deg) brightness(95%) contrast(104%);
}
 
.stakeway-footer-link4 {
  display: contents;
}
 
.stakeway-footer-telegram {
  width: 36px;
  height: 36px;
  transition: 0.3s;
  text-decoration: none;
}
 
.stakeway-footer-telegram:hover {
  filter: brightness(0) saturate(100%) invert(19%) sepia(86%) saturate(7464%) hue-rotate(251deg) brightness(95%) contrast(104%);
}
 
.stakeway-footer-link5 {
  display: contents;
}
 
.stakeway-footer-discord {
  width: 36px;
  height: 36px;
  transition: 0.3s;
  text-decoration: none;
}
 
.stakeway-footer-discord:hover {
  filter: brightness(0) saturate(100%) invert(19%) sepia(86%) saturate(7464%) hue-rotate(251deg) brightness(95%) contrast(104%);
}
 
.stakeway-footer-stay-in-touch {
  gap: var(--dl-layout-space-halfunit);
  width: 360px;
  height: 230px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
  flex-direction: column;
}
 
.stakeway-footer-text4 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 30px;
  font-stretch: normal;
  text-decoration: none;
}
 
.stakeway-footer-text5 {
  color: rgba(0, 0, 0, 1);
  width: 360px;
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.stakeway-footer-form {
  gap: var(--dl-layout-space-halfunit);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
 
.stakeway-footer-message {
  gap: -4px;
  width: 100%;
  height: auto;
  display: none;
  padding: 8px 16px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
}
 
.stakeway-footer-text6 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.stakeway-footer-logo {
  width: 66px;
  height: 80px;
}
 
@media(max-width: 1200px) {
  .stakeway-footer-container1 {
    height: auto;
  }
  .stakeway-footer-text1 {
    font-family: Gotham-Bold;
  }
  .stakeway-footer-text3 {
    font-family: Gotham-Bold;
  }
  .stakeway-footer-text4 {
    font-family: Gotham-Bold;
  }
  .stakeway-footer-text5 {
    font-family: Gotham-Book;
  }
}
 
@media(max-width: 767px) {
  .stakeway-footer-content {
    width: 100%;
    flex-direction: column;
  }
  .stakeway-footer-column1 {
    order: 2;
  }
  .stakeway-footer-column2 {
    order: 3;
  }
  .stakeway-footer-logo {
    order: 1;
  }
}
 
@media(max-width: 550px) {
  .stakeway-footer-content {
    gap: var(--dl-layout-space-twounits);
    flex-direction: column;
  }
  .stakeway-footer-column1 {
    order: 2;
  }
  .stakeway-footer-text2 {
    font-family: Gotham-Bold;
  }
  .stakeway-footer-column2 {
    order: 3;
    width: 100%;
  }
  .stakeway-footer-stay-in-touch {
    width: 100%;
  }
  .stakeway-footer-logo {
    order: 1;
  }
}
