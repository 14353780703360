.tag-container1 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: #8950FA;
}
 
.tag-container2 {
  gap: 10px;
  width: auto;
  height: auto;
  display: flex;
  padding: 6px 12px;
  align-items: center;
  justify-content: center;
}
 
.tag-text {
  fill: var(--dl-color-default-maintext);
  color: var(--dl-color-default-maintext);
  font-size: 12px;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
}
 

 

 
.tagroot-class-name2 {
  background-color: var(--dl-color-default-blue);
}
 
.tagroot-class-name3 {
  background-color: var(--dl-color-default-blue);
}
 
.tagroot-class-name4 {
  background-color: var(--dl-color-default-blue);
}
 
.tagroot-class-name5 {
  background-color: var(--dl-color-default-blue);
}
 
.tagroot-class-name6 {
  background-color: var(--dl-color-default-blue);
}
 
.tagroot-class-name7 {
  background-color: var(--dl-color-default-blue);
}
 
.tagroot-class-name8 {
  background-color: var(--dl-color-default-blue);
}
 
.tagroot-class-name9 {
  background-color: var(--dl-color-default-blue);
}
 
.tagroot-class-name10 {
  background-color: var(--dl-color-default-blue);
}
 
.tagroot-class-name11 {
  background-color: var(--dl-color-default-blue);
}
 

 

 

 
.tagroot-class-name15 {
  background-color: var(--dl-color-default-success);
}
